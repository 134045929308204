import React, { useState } from "react"
import { Page, pdfjs } from "react-pdf"
import { Document } from "react-pdf/dist/esm/entry.webpack"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import Layout from "../components/common/layout/layout"
import PrivateContent from "../components/user/privatecontent"

import { graphql } from "gatsby"

import PageBanner from "../components/common/misc/pagebanner"
import Loader from "react-loader-spinner"

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf/pdf.worker.min.js'

const PDFContainer = (props) => {
  const pdfData = props.data.pdf
  return (
    <Layout>
      <PrivateContent accessLevel={"requirespaidsub"}>
        <PDFView
          pdfData={pdfData}
          playTitle={props.pageContext.playTitle}
          sectionTitle={props.pageContext.sectionTitle}
        />
      </PrivateContent>
    </Layout>
  )
}
const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
}

const PDFView = (props) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setIsLoading] = useState(true)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setIsLoading(false)
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  var userGreet = "Welcome!"

  if (props.subInfo) {
    userGreet = props.subInfo.userGreet
  }

  return (
    <>
      <PageBanner
        title={`${props.sectionTitle} - ${props.playTitle}`}
        userGreet={userGreet}
      />
      <div className="container user-login-form-container profile-form-outer pt-1">
        <div className={`${loading ? "" : "is-hidden"}`}>
          <div className="has-text-centered my-2">
            <Loader type="TailSpin" color="#000000" height={100} width={100} />
          </div>
          <div className="title is-5 has-text-centered mt-3">
            A Moment, Please
          </div>
        </div>
        <div className={`columns is-centered ${loading ? 'is-hidden' : ''}`}>
          <div className="column">
            <Document
              file={props.pdfData.publicURL}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              <div className="is-hidden-mobile">
              <Page pageNumber={pageNumber}/>
              </div>
              <div className="is-hidden-tablet">
              <Page pageNumber={pageNumber} width="400"/>
              </div>
            </Document>
            <div className="columns is-mobile mx-0 px-0 pt-2">
              <div className="column px-0 mx-0">
                <button
                  className="button is-black"
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Previous
                </button>
              </div>
              <div className="column">
                <p className="title is-6 has-text-centered">
                  Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"}
                </p>
                <a href={props.pdfData.publicURL} download>
                  <p className="title is-6 has-text-sibredorange has-text-centered">
                    Download PDF
                  </p>
                </a>
              </div>
              <div className="column is-pulled-right">
                <button
                  className="button is-black is-pulled-right"
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query PDFFile($pdfPath: String) {
    pdf: file(relativePath: { eq: $pdfPath }) {
      name
      extension
      publicURL
    }
  }
`

export default PDFContainer
